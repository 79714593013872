<template lang="pug">
.z-top.relative
  q-card.overflow-hidden.text-left.no-shadow
    //- q-chip.z-top.absolute-top-right(v-if="config.debug") {{ submission.id }} {{ submission.status }}
    .bg-warning.q-pa-xs.text-white(
      v-if="submission.error"
    ) {{ $t('resubmit_message') }}
    .row(style="position:relative;")
      q-responsive(
        v-if="submission.status == 'edited' || submission.status == 'published'",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :class="{ col: $q.screen.gt.sm }"
      )
        q-carousel.bg-grey-4(
          animated,
          transition-prev="slide-right",
          transition-next="slide-left",
          v-model="activeSlide",
          control-type="regular",
          control-color="primary",
          control-text-color="white",
          swipeable,
          infinite
        )
          
          q-carousel-slide.cursor-pointer(
            key="hd",
            name="hd",
            v-if="submission.subtype !=='text'"
            @click="$emit('showvideo', 'hd_video')",
            :img-src="getThumbnailHd(submission)"
          )
            .absolute-center
              q-icon(:name="matPlayArrow", color="white", size="5em")
            

      q-img.no-border-radius.cursor-pointer(
        v-if="submission.status == 'readyformoderation' || submission.status == 'transcribed' && !submission.error",
        :ratio="16 / 9",
        :src="getThumbnailHd(submission)"
        style="width: 100%; max-width: 100%",
        :class="{ col: $q.screen.gt.sm }"
      )
        .absolute-full.text-subtitle2.flex.flex-center
          q-icon(:name="matGavel", size="lg")
          div {{ $t('under_review') }}




      q-img.no-border-radius.cursor-pointer(
        v-if="submission.status == 'moderated'",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :src="getThumbnailHd(submission)"
        :class="{ col: $q.screen.gt.sm }"
      )
        .absolute-full.text-subtitle2.flex.flex-center
          q-icon(:name="matHourglassBottom", size="lg")
          div {{ $t('currently_editing') }}

      q-img.no-border-radius.cursor-pointer.bg-grey-2(
        v-if="submission.status == 'draft' || submission.status == 'error_transcode' || submission.status == 'rejected' || submission.status == 'transcribed' || submission.status == 'submitted' || submission.status == 'error' || submission.status == 'transcoded' || submission.status.startsWith('edited_')",
        :ratio="16 / 9",
        style="width: 100%; max-width: 100%",
        :class="{ col: $q.screen.gt.sm }"
        :src="(submission.status!='draft')?getThumbnailHd(submission):''"
      )
        .absolute-full.text-subtitle2.flex.flex-center(
          v-if="submission.status == 'transcoded' || submission.status == 'submitted' || submission.status.startsWith('edited_')"
        )
          q-icon(:name="matSettings", size="lg")
          div {{ $t('processing') }}

        .absolute-full.text-subtitle2.flex.flex-center(
          v-if="submission.status == 'draft' || submission.status == 'error_transcode'"
        )
          q-icon(:name="matInfo", size="lg")
          div {{ $t('incomplete_submission') }}


        .absolute.text-subtitle2(
          v-if="submission.status == 'rejected'"
          style="top:0;left:0;width:100%;height:100%;"
        )
          .row.full-height
            .col-auto
              q-icon(:name="matDangerous", size="lg")
            .col
              .q-pt-sm.scroll.full-height {{ $t('reject_reason',[submission.rejectreason]) }}

        .absolute-full.text-subtitle2.flex(
          v-if="submission.status == 'error' || submission.error"
        )
          q-icon(:name="matWarning", size="lg")
          div.q-pt-sm {{ $t('editing_error',[submission.error]) }}
      .absolute-full.text-subtitle2.flex.flex-center.text-black(v-if="submission.subtype === 'text' && submission.status != 'rejected' && submission.status != 'readyformoderation'")
        div {{ $t('text_entry') }}
    q-separator.q-mb-sm
    q-card-section.q-pa-xs.q-pl-sm
      //- .text-h6 {{$t('complete_submission')}}
      .row
        .col
          q-chip(
            rounded,
            :name="getTagIcon(submission.oneTag)",
            color="primary",
            text-color="white"
          ) {{ $t('tags.' + submission.oneTag) }}
          q-chip.text-white(
            rounded,
            v-for="tag of submission.tags",
            :key="tag",
            
            :name="getTagIcon(tag)",
            color="primary",
          ) {{ $t('tags.' + tag) }}

        .col-auto
          q-btn(:icon="matShare" flat @click="share(submission)" v-if="canShare").text-primary

    q-card-section.q-pa-sm
      .row(v-if="submission.judgingstatus==='feedback_sent'")
        q-btn(:icon="matRateReview" flat @click="$emit('showfeedback',submission)").text-primary {{ $t('view-feedback') }}

      .row(
        v-if="submission.status == 'draft' || submission.status == 'error_transcode'"
      )
        q-btn(
          :to="'/submission/continue/' + submission.id",
          rounded,
          color="accent",
          unelevated
        ) {{ $t('complete_submission') }}

  .text-uppercase.text-white.text-weight-bold.text-caption(
    :class="{ 'float-right': dir == 1, 'float-left': dir == 0 }",
    rounded,
    icon="history",
    dense
  ) {{ getTime(submission.createdAt).toRelative() }}
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  // import { openURL } from "quasar";
  import find from "lodash/find";
  import Language from "@/mixins/Language";
  // import AddThis from "@/components/AddThis";
  // import { fabLinkedinIn } from "@quasar/extras/fontawesome-v5";
  import { copyToClipboard } from "quasar";

  export default {
    name: "SubmissionItem",
    props: ["submission", "config", "dir"],
    mixins: [Language],
    components: {},
    data: () => {
      return {
        activeSlide: "hd",
        linkedInShare: false,
        postToLinkedIn: false,
        linkedInLink: null,
        // shareText: null,
      };
    },
    created() {
      // this.fabLinkedinIn = fabLinkedinIn;
    },
    async mounted() {
      this.linkedInLink = this.submission.linkedinPost;
      // this.shareText =
      //   this.getShareText(this.submission) + this.getUrl(this.submission);
      // await this.linkedinUser();
    },
    computed: {
      canShare() {
        return (
          this.submission.status == "edited" ||
          this.submission.status == "published"
        );
      },
      ...mapState(["user"]),
      ...mapGetters(["getTagIcon"]),
    },
    methods: {
      share(submission) {
        //copy text:

        const text = this.getShareText(submission);
        copyToClipboard(text)
          .then(() => {
            // success!
            this.$q.notify({
              type: "positive",
              message: `Social media post copied!`,
            });
          })
          .catch(() => {
            // fail
            this.$q.notify({
              type: "negative",
              message: `Can't copy social media post!`,
            });
          });
      },
      getUrl(submission) {
        let hd = find(submission.media, (media) => {
          if (media.type === "hd_video" && media.status !== "exporting")
            return true;
        });
        // console.log("https://youtu.be/" + hd.youtube);
        return hd ? "https://youtu.be/" + hd.youtube : "";
      },
      getYouTube(submission) {
        let hd = find(submission.media, (media) => {
          if (media.type === "hd_video" && media.status !== "exporting")
            return true;
        });
        return hd ? `https://www.youtube.com/embed/${hd.youtube}` : "";
      },
      getShareText(submission) {
        let social = find(submission.media, { type: "social" });
        if (social) {
          return social.content.replace(this.getUrl(submission), "");
        } else return "";
      },
      getS3Link(src) {
        if (!src) return "";
        return `${this.config.cloudfronturl}${src
          .replace("s3://", "")
          .replace(this.config.bucketname, "")}`;
      },
      getThumbnailHd(submission) {
        try {
          let raw = find(submission.media, { type: "raw" });
          return `${this.getS3Link(raw.src).replace(".mp4", "")}.0000001.jpg`;
        } catch {
          return "";
        }
      },
      getThumbnailSquare(submission) {
        let raw = find(submission.media, { type: "square_video_transcoded" });
        if (raw)
          return `${this.getS3Link(raw.src).replace(".mp4", "")}.0000001.jpg`;
        else return this.getThumbnailHd(submission);
      },
      getEditedVideo(submission) {
        return find(submission.media, (media) => {
          if (media.type === "hd_video" && media.status !== "exporting")
            return true;
        });
      },
      ...mapActions(["updateSubmission"]),
    },
  };
</script>

<style lang="scss" scoped>
  .custom-caption {
    text-align: center;
    padding: 12px;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .custom-linkedin {
    background-color: #0077b5;
    color: white;
    height: 32px;
    border-radius: 0;
    margin-right: 6px;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }

  .custom-linkedin:hover {
    transform: translate(0, -5px);
    -webkit-transform: translate(0, -5px);
    -o-transform: translate(0, -5px);
    -moz-transform: translate(0, -5px);
  }
</style>
