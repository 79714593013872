<template lang="pug">
q-page.overflow-hidden(style="margin-top: -35px")
  q-banner.fixed-bottom.z-max.bg-red-2.q-ma-lg(v-if="error") There has been an error, please logout and try again
    div
      em {{ error }}
  .row.items-end
    .col-12.col-sm
      q-breadcrumbs.q-mt-xl.q-pt-lg.q-ma-dynamic(gutter="xs")
        template(v-slot:separator)
          q-icon(size="1.3em", :name="matChevronRight", color="primary")
        q-breadcrumbs-el.text-dark(:label="$t('home')", to="/")
        q-breadcrumbs-el.text-dark(:label="$t('academy.title')")
        q-breadcrumbs-el.text-primary(:label="$t('mylimitless')")
    .col-auto
      q-btn.q-ma-dynamic(
        dense,
        padding="0",
        color="primary",
        flat,
        no-caps,
        to="/submission/portfolio"
      ) Submit Portfolio

  .row.relative-position.hero1.items-center
    .col
      .row.justify-center.items-center.full-height
        .col-10.q-py-xl.text-center.col-md-8
          .row.justify-center.items-top
            .text-h1.q-mb-xl {{ $t("hi") }} &nbsp;
              span.bg-lilac.q-px-xs {{ user.displayName }}!
                |
                q-tooltip {{ $t("click-to-change") }}
              q-popup-edit(
                @save="displayName.length ? saveDisplayName() : false",
                v-model="displayName"
              )
                q-input(
                  v-model="displayName",
                  autofocus,
                  required,
                  outlined,
                  :rules="[(val) => !!val || '']",
                  :label="$t('your_name')"
                )
            q-icon.q-mb-xs.q-ml-sm(:name="matEdit", size="xs")
          .text-h1 {{ $t("lets-continue-this-exciting") }} &nbsp;
            .inline-block.bg-lilac.q-px-xs {{ $t("journey-together") }}
          div(v-if="canSubmit()() && !loading")
            .row.q-mt-xl.justify-center
              q-btn(
                unelevated,
                color="accent",
                no-caps,
                text-color="black",
                to="/submission/make",
                align="between",
                style="min-width: 300px",
                size="xl"
              )
                div {{ $t("start_journey") }}
                q-icon(right, :name="matNorthEast")
                //- .text-body1.q-mt-xl {{ $t('if-there-are-any-updates-well-get-in-touch-with-you-at') }}&nbsp;
                  span.bg-accent.q-px-xs {{user.email}}

  .row.justify-center.relative-position.q-pa-dynamic(
    v-if="hasAcceptedSubmission"
  )
    .col-10.q-pb-xl.text-center.col-md-6
      .row.justify-center.items-top.q-mb-xl
        .col.bg-primary.text-white
          .q-pa-md
            .text-body1 {{ $t("use-the-following-code-to-verify-yourself-when-arranging-a-your-financial-transfer") }}
          .bg-secondary.text-white.q-pa-md.text-h3 {{ moneyCode }}

  .row.justify-center.bg-secondary.relative-position.text-white.q-pa-dynamic
    .hero-border &nbsp;
    .col-md-12.col-12
      .q-pt-xl.q-pb-xl.q-mb-xl
        .row.justify-center.q-mt-xl
          .col-12.col-md-8
            .text-center.text-h1.q-my-xl.text-bold {{ $t("how_to_submit") }}

        .row.text-center.justify-center.text-body1.q-px-md.q-px-md
          .col-md-2.col-12.q-px-lg
            q-img.q-mb-md(
              style="max-height: 250px; max-width: 50vw",
              src="/img/step1.webp",
              :ratio="1",
              contain
            )
            div
              span.bg-white.text-secondary.text-body2.q-pa-xs {{ $t("step-1") }}
            div(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t("pipeline0") }}
          .col-md-2.col-12.q-px-lg
            q-img.q-mb-md(
              contain,
              :ratio="1",
              style="max-height: 250px; max-width: 50vw",
              src="/img/step2.webp"
            )
            div
              span.bg-white.text-secondary.text-body2.q-pa-xs {{ $t("step-2") }}
            div(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t("pipeline1") }}
          //- .col-auto.dots(v-if="$q.screen.gt.sm")
            q-icon(:name="matMoreHoriz", size="40px")

          .col-md-2.col-12.q-px-lg
            q-img.q-mb-md(
              contain,
              :ratio="1",
              style="max-height: 250px; max-width: 50vw",
              src="/img/step3.webp"
            )
            div
              span.bg-white.text-secondary.text-body2.q-pa-xs {{ $t("step-3") }}
            div(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t("pipeline2") }}
          //- .col-auto.dots(v-if="$q.screen.gt.sm")
          //-   q-icon(:name="matMoreHoriz", size="40px")

          .col-md-2.col-12.q-px-lg
            q-img.q-mb-md(
              contain,
              :ratio="1",
              style="max-height: 250px; max-width: 50vw",
              src="/img/step4.webp"
            )
            div
              span.bg-white.text-secondary.text-body2.q-pa-xs {{ $t("step-4") }}
            div(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t("pipeline3") }}
          //- .col-auto.dots(v-if="$q.screen.gt.sm")
          //-   q-icon(:name="matMoreHoriz", size="40px")

          .col-md-2.col-12.q-px-lg
            q-img.q-mb-md(
              contain,
              :ratio="1",
              style="max-height: 250px; max-width: 50vw",
              src="/img/step5.webp"
            )
            div
              span.bg-white.text-secondary.text-body2.q-pa-xs {{ $t("step-5") }}
            div(:class="{ 'q-mb-md': $q.screen.lt.md }") {{ $t("pipeline4") }}
            //- div.q-mt-lg.text-primary() {{ $t('pipeline5') }}

    .text-h1.text-center.q-mb-xl {{ $t("instructions_title") }}
      //- span.bg-accent.q-px-xs {{ $t('smooth_submission') }}
    .row.text-body1.q-mb-xl.q-pb-xl
      .col-md-6.col-12
        .q-pl-lg.q-pl-xl
          ul.coloredbullets
            li(
              v-for="(message, index) in Object.values(messages.instructions)",
              :key="index",
              v-if="index % 2 == 0"
            ) {{ message }}
      .col-md-6.col-12
        .q-pl-lg.q-pl-xl
          ul.coloredbullets
            li(
              v-for="(message, index) in Object.values(messages.instructions)",
              :key="index",
              v-if="index % 2 == 1"
            ) {{ message }}
            //- li(v-for="(message, index) in second", :key="index") {{ message }}
            li {{ $t("instruction_agree") }} <br />
            a.text-white(href="/policy", target="_blank") {{ $t("terms_privacy_policy") }}

    .hero-borderb &nbsp;

  .q-ma-dynamic.q-mb-xl(
    style="margin-top: 8em; margin-bottom: 6em",
    v-if="canSubmit()() && !loading"
  )
    .text-center
      .text-h1 {{ $t("we-are-currently-accepting-submissions-for") }} &nbsp;
      br
      span.text-h1.bg-accent.q-px-xs {{ $t(`phases.${config.current_phase}.title`) }}
      .text-body1.q-mt-lg {{ $t(`phases.${config.current_phase}.description`) }}
      .text-body1.q-mt-lg.text-secondary {{ $t("respond-to-the-following-prompts") }}
      .row.q-col-gutter-lg.text-white.items-stretch.text-body1.q-mt-sm.q-mb-sm.justify-center
        .col-12.col-md-4.col-md(v-for="(n, index) in numVideos", :key="index")
          .q-pa-md.bg-secondary.full-height
            .text-body1 {{ $t(`phases.${config.current_phase}.vid${index + 1}.title`) }}
            .text-body2 {{ $t(`phases.${config.current_phase}.vid${index + 1}.description`) }}

      .row.q-mt-xl.justify-center
        q-btn(
          unelevated,
          color="accent",
          no-caps,
          text-color="black",
          to="/submission/make",
          align="between",
          style="width: 300px",
          size="xl"
        )
          div {{ $t("submit_now") }}
          q-icon(right, :name="matNorthEast")

  .q-pa-dynamic.bg-primary
    .row.justify-center.text-white(style="padding-bottom: 180px")
      .col-md-8.col-12
        .col.q-ma-lg.text-center
          q-spinner(size="xl", v-if="loading")
        #my
        .q-pa-md.q-mt-xl(v-if="!loading")
          .text-h1.q-mb-xl.text-center {{ $t("your-journey-so-far") }}
          .row.pushdown.q-ml-xs
            q-timeline(color="secondary", :layout="layout", v-if="config")
              q-timeline-entry.relative(
                :title="$t('submissions_opened')",
                :subtitle="getTime(config.opensAt).toRelative()"
              )
                //- template(v-slot:subtitle)
                //-   .ripped.rip1
                //-   .mytitle {{ getTime(config.opensAt).toRelative() }}

              q-timeline-entry.relative(
                v-for="(phase, index) in phases",
                :key="phase.code",
                :icon="getIcon(phase)",
                :title="$t(`phases.${phase.code}.title`)",
                :subtitle="getOpenCloseTime(phase).toRelative()",
                :side="index % 2 ? 'right' : 'left'"
              )
                //- template(v-slot:subtitle)
                //-   .ripped(:class="'rip' + (index + 2)")
                //-   .mytitle {{ getOpenCloseTime(phase).toRelative() }}
                //- div {{getSubmissionList(phase.code)}}
                .q-mb-xl.q-pt-md(
                  v-if="canSubmit()() && phase.code == config.current_phase"
                )
                  q-btn(
                    unelevated,
                    color="accent",
                    align="between",
                    text-color="black",
                    no-caps,
                    to="/submission/make",
                    style="width: 300px",
                    size="lg"
                  ) 
                    |
                    |
                    |
                    div {{ $t("submit_now") }}
                    //- div(v-if="config.current_phase !== 'submit'") {{ $t('upload_draft') }}
                    q-icon(right, :name="matNorthEast")

                .q-my-lg(
                  v-if="canSubmit() && isOnWhiteList()() === false && phase.code == config.current_phase"
                )
                  .text-white {{ $t("whitelisterror") }}

                SubmissionItem.q-mb-xl(
                  :class="{ maxwidth: $q.screen.gt.sm }",
                  v-for="submission in getSubmissionList(phase.code)",
                  :dir="layout != 'loose' ? 1 : index % 2",
                  :key="submission.id",
                  v-on:showvideo="showVideo($event, submission)",
                  v-on:showfeedback="showFeedback($event, submission)",
                  :submission="submission",
                  :config="config",
                  v-if="getSubmissionList(phase.code).length > 0"
                )

              q-timeline-entry(
                :side="phases.length % 2 ? 'right' : 'left'",
                :title="$t('academy_opens')",
                :subtitle="getTime(config.closesAt).toRelative()"
              )
  q-dialog(v-model="playVideo")
    q-card(style="width: 800px; max-width: 90vm")
      q-video(
        v-if="currentVideo",
        :src="getYouTube(currentVideo)",
        :ratio="currentVideo.type == 'hd_video' ? 16 / 9 : 1"
      )
      q-btn.full-width(
        :icon-right="matDownload",
        color="grey-5",
        dense,
        flat,
        type="a",
        :href="getNonTubeLink(currentVideo)",
        target="_blank"
      ) {{ $t("no_youtube") }}

  q-dialog(v-model="needsProfile", persistent)
    q-card.full-width
      q-card-section
        .text-h5.q-mb-lg {{ $t("your_name") }}
        div(style="position: relative")
          q-input(
            autofocus,
            v-model="displayName",
            outlined,
            required,
            :rules="[(val) => !!val || '']"
          )
            template(v-slot:after)
              q-btn.full-width(
                square,
                dense,
                unelevated,
                :icon="matCheck",
                color="accent",
                @click="displayName.length ? saveDisplayName() : false"
              )
          .floatinglabel
            .row
              .col-auto
                .bg-lilac.q-pa-xs.text-dark {{ $t("enter-your-name") }}
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import filter from "lodash/filter";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import Language from "@/mixins/Language";
import SubmissionItem from "@/components/SubmissionItem";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { DateTime } from "luxon";

export default {
  mixins: [Language],
  name: "SubmissionList",
  components: {
    SubmissionItem,
    LocaleSwitcher,
  },
  async mounted() {
    await Promise.all([this.getSubmissions(), this.initConfig()]);
    this.loading = false;
  },
  data: () => {
    return {
      loading: true,
      playVideo: false,
      currentVideo: null,
      photofile: [],
      displayName: "",
      inlineloading: false,
    };
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.$q.loading.show({
          //   delay: 400, // ms
          // });
        } else {
          // this.$q.loading.hide();
        }
      },
    },
    inlineloading: {
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
    async photofile() {
      try {
        this.inlineloading = true;
        // console.log(this.photofile);
        if (this.photofile[0]) {
          await this.updateUserPhoto(this.photofile[0]);
        }
      } catch (e) {
        console.log(e);
        this.showError("photo_upload_fail");
      }
      this.inlineloading = false;
    },
  },
  computed: {
    moneyCode() {
      return find(this.submissions, {
        hasMoney: true,
      }).moneyCode;
    },
    hasAcceptedSubmission() {
      // console.log(this.submissions);
      return (
        filter(this.submissions, {
          hasMoney: true,
        }).length > 0
      );
    },
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    needsProfile() {
      // console.log(this.user);
      return (
        this.user.displayName === null ||
        this.user.displayName === "" ||
        this.user.displayName === "Innovator"
      );
    },
    numVideos() {
      let phase = find(this.config.phases, {
        code: this.config.current_phase,
      });
      return parseInt(phase.noOfVideos);
    },
    layout() {
      return this.$q.screen.lt.sm
        ? "dense"
        : // : this.$q.screen.lt.md
          // ? "comfortable"
          "comfortable";
    },
    phases() {
      if (this.config) {
        let avail = filter(this.config.phases, "visible");
        return avail;
      } else {
        return [];
      }
    },
    ...mapState(["config", "submissions", "user", "error"]),
  },
  methods: {
    ...mapGetters(["canSubmit", "isOnWhiteList"]),
    getIcon(phase) {
      // console.log(phase.icon);
      switch (phase.icon) {
        case "lightbulb":
          return this.matLightbulb;
        case "newspaper":
          return this.matNewspaper;
        case "emoji_events":
          return this.matEmojiEvents;
        case "gavel":
          return this.matGavel;
        default:
          return phase.icon;
      }
    },
    getNonTubeLink(media) {
      if (!media) return "";
      if (!media.src) return "";
      return `${this.config.cloudfronturl}${media.src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")}`;
    },
    async saveDisplayName() {
      try {
        this.inlineloading = true;
        // console.log(this.displayName);
        await this.updateUserDisplayName(this.displayName);
      } catch (e) {
        console.log(e);
        this.showError("photo_upload_fail");
      }
      this.inlineloading = false;
    },
    async updatePhoto() {
      this.$refs.photofile.$el.click();
    },
    ifCanChangeImage() {
      let isemail = find(this.user.providerData, { providerId: "password" });
      return isemail ? true : false;
    },
    getYouTube(media) {
      // console.log(media);
      if (media.youtube)
        return `https://www.youtube.com/embed/${media.youtube}`;
      else return this.getNonTubeLink(media);
    },
    showVideo(kind, submission) {
      // console.log(kind);
      this.playVideo = true;
      this.currentVideo = find(submission.media, (media) => {
        if (media.type === kind && media.status !== "exporting") return true;
      });
    },
    showFeedback(submission) {
      // console.log(kind);
      this.playVideo = true;
      this.currentVideo = {
        type: "hd_video",
        src: `/submissions/${submission.id}/feedback/hd_video.mp4`,
      };
      // console.log(this.currentVideo);
    },
    getOpenCloseTime(phase) {
      if (this.getTime(phase.closesAt) < DateTime.now())
        return this.getTime(phase.closesAt);
      else return this.getTime(phase.opensAt);
    },
    isOpen(phase) {
      if (!phase) return false;
      return this.getTime(phase.opensAt) > DateTime.now();
    },
    getSubmissionList(code) {
      // console.log(code);
      // console.log(this.submissions);
      return filter(
        orderBy(this.submissions, ["createdAt._seconds"], ["desc"]),
        {
          phase: code,
        }
      );
    },
    ...mapActions([
      "signOutAction",
      "initConfig",
      "getSubmissions",
      "updateUserDisplayName",
      "updateUserPhoto",
    ]),
  },
};
</script>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>

<style lang="scss" scoped>
@import "@/styles/quasar.variables.scss";

ul.coloredbullets {
  list-style: none;
  margin: 0;
  padding: 0;

  li::before {
    content: "• ";
    font-size: 1.2em;
    color: $lilac;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.hero-border {
  // border-bottom: $lilac 60px solid;
  background-color: $lilac;
  // background: url("~@/assets/img/lilacborder.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  top: -20px;
  height: 50px;
  // left: -1px;
  width: 100%;
  transform: skew(0, -0.5deg);
  overflow: hidden;
}

.hero-borderb {
  // border-bottom: $lilac 60px solid;
  background-color: $lilac;
  // background: url("~@/assets/img/lilacborder.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: -20px;
  height: 50px;
  // left: -1px;
  width: 100%;
  transform: skew(0, 0.5deg);
  overflow: hidden;
}

.hero1 {
  height: 650px;
  max-height: 70vh !important;
}
</style>

<style lang="scss">
@import "@/styles/quasar.variables.scss";

ol.primary-list {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
  li {
    counter-increment: my-awesome-counter;
    margin: 0 0 0.5rem 0;
    position: relative;
  }

  li::before {
    content: counter(my-awesome-counter);
    color: $primary;
    font-size: 1.5rem;
    // font-weight: bold;
    position: absolute;
    --size: 24px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    // background: black;
    // border-radius: 50%;
    text-align: center;
  }
}

.maxwidth {
  max-width: 80%;
}

.badge {
  background: $primary;
  border-top-left-radius: 15px;
  color: white;

  .text-title {
    font-size: 1.5em;
    text-transform: uppercase;
    font-style: italic;
  }

  .text-item {
    border-bottom: dotted 1px $grey-5;
    .col {
      text-transform: uppercase;
      padding-right: 10px;
      font-size: 0.8em;
    }
    .bold {
      text-transform: uppercase;
      font-style: italic;
      font-size: 1em;
    }
  }
}

.mytitle {
  .q-timeline--comfortable & {
    padding-bottom: 230px;
    opacity: 0.4;
  }

  .q-timeline--dense & {
    // padding-top: 0px;
    opacity: 0.4;
  }
}

.dim {
  opacity: 0.6;
}

// .pushdown {
//   margin-top: 7em;
//   body.screen--xs & {
//     margin-top: 2em !important;
//   }
// }

// @import "@/styles/quasar.variables.scss";
.q-timeline__dot {
  // width: 8px !important;
  min-width: inherit !important;
  width: 16px !important;
  height: 16px !important;
  color: white;
  @media (max-width: $breakpoint-sm-max) {
    height: 100% !important;
  }
}

.q-timeline__dot:after {
  background-color: $accent !important;
  width: 4px !important;
  transform: translateX(-2px);
  opacity: 1;
}

.q-timeline__dot:before {
  background-color: white !important;
  width: 16px !important;
  transform: translateX(-2px);
  border-radius: 0 !important;
}

.q-timeline__title {
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
}

.q-timeline__caption {
  color: $secondary;
  font-size: 1rem !important;
  line-height: 1.6rem !important;
}

.q-timeline__subtitle {
  opacity: 1 !important;
  // color: white !important;
}

// .q-timeline__dot .q-icon {
//   /* line-height: 30px; */
//   height: 25px !important;
//   /* width: 25px !important; */
//   top: 7px !important;
//   /* left: 5px !important; */
// }

.dots {
  margin-top: 15px;
}

.nobullet {
  list-style: none;
}

.bg-lilac {
  background-color: $lilac;
}
</style>
